import { v4 as uuidv4 } from "uuid";

export const nodeId = () => {
  return "node" + uuidv4();
};

export const wireId = () => {
  return "wire" + uuidv4();
};

export const targetIsNode = (target) => {
  return target.attrs && target.attrs.id && target.attrs.id.startsWith("node");
};

export const targetIsWire = (target) => {
  return target.attrs && target.attrs.id && target.attrs.id.startsWith("wire");
};
