import React, { Component, createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

export const POINTER = "pointer";
export const NODE = "node";
export const WIRE = "wire";
export const SELECT = "select";
export const MESH = "mesh";
export const ROD = "rod";
export const EXCITATION = "excitation";
export const DELETE = "delete";
export const UNDO = "undo";
export const REDO = "redo";
export const SETTINGS = "settings";
export const DIVIDE = "divide";

class ToolBtn extends Component {
  constructor(props) {
    super(props);
    this.btnRef = createRef();
  }

  render() {
    const style = this.props.selected ? "btn-secondary" : "btn-default";
    return (
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 100 }}
        overlay={(props) =>
          this.props.tooltip ? (
            <Tooltip {...props}>{this.props.tooltip}</Tooltip>
          ) : (
            <div />
          )
        }
      >
        <Button
          className={`btn ${style} btn-square`}
          ref={this.btnRef}
          onClick={() => {
            this.btnRef.current.blur();
            this.props.onClick();
          }}
        >
          <FontAwesomeIcon icon={this.props.icon} style={{ width: 30 }} />
        </Button>
      </OverlayTrigger>
    );
  }
}

export default ToolBtn;
