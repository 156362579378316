import React, { Component } from "react";

class FooterWhite extends Component {
  render() {
    return (
      <div>
        {/*<!-- FOOTER -->-->*/}
        <footer className="py-8 py-md-11 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                {/*<!-- Brand -->*/}
                <div className="text-black">TRAGSYS</div>
                {/*<!-- Text -->*/}
                <p className="text-gray-700 mb-2">
                  A better way to build grounding systems.
                </p>
                <p className="text-muted mb-2" style={{ fontSize: 12 }}>
                  Copyright&copy; 2001-2022 Leonid Grcev
                </p>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </footer>
      </div>
    );
  }
}

export default FooterWhite;
