export const closestNode = (nodes, point, stickyDistance) => {
  if (!nodes) {
    return null;
  }
  var result = null;
  var minDistance = Infinity;

  for (var i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    const distance = Math.hypot(node.x - point.x, node.y - point.y);
    if (
      minDistance > distance &&
      (!stickyDistance || stickyDistance > distance)
    ) {
      minDistance = distance;
      result = node;
    }
  }

  return result;
};

export const scaled = (val, min, scale) => {
  const newVal = val / (1 + scale);
  return Math.max(min, newVal);
};

export const intersection = (w1, w2) => {
  const p0_x = w1.start.x,
    p0_y = w1.start.y,
    p1_x = w1.end.x,
    p1_y = w1.end.y;
  const p2_x = w2.start.x,
    p2_y = w2.start.y,
    p3_x = w2.end.x,
    p3_y = w2.end.y;

  var s1_x, s1_y, s2_x, s2_y;
  s1_x = p1_x - p0_x;
  s1_y = p1_y - p0_y;
  s2_x = p3_x - p2_x;
  s2_y = p3_y - p2_y;

  const d = -s2_x * s1_y + s1_x * s2_y;

  if (d === 0) {
    return null;
  }

  var s, t;
  s = (-s1_y * (p0_x - p2_x) + s1_x * (p0_y - p2_y)) / d;
  t = (s2_x * (p0_y - p2_y) - s2_y * (p0_x - p2_x)) / d;

  if (s >= 0.0 && s <= 1 && t >= 0.0 && t <= 1) {
    // Collision detected
    return { x: p0_x + t * s1_x, y: p0_y + t * s1_y };
  }

  return null; // No collision
};

const extraWidth = 1.3;

const multipliers = (screen, areaSize) => {
  let wMult = 1.0;
  let hMult = 1.0;

  if (screen.width > screen.height) {
    wMult = (screen.width / screen.height) * areaSize;
    hMult = areaSize;
  } else {
    hMult = (screen.height / screen.width) * areaSize;
    wMult = areaSize;
  }

  return { wMult, hMult };
};

const p2d_inner = (point, screen, areaSize) => {
  const { wMult, hMult } = multipliers(screen, areaSize);

  const x = (point.x / screen.width) * (wMult * extraWidth);
  const y = (point.y / screen.height) * (hMult * extraWidth);

  return { x, y };
};

const d2p_inner = (point, screen, areaSize) => {
  const { wMult, hMult } = multipliers(screen, areaSize);

  const x = (point.x / (wMult * extraWidth)) * screen.width;
  const y = (point.y / (hMult * extraWidth)) * screen.height;

  return { x, y };
};

const noNaN = (aX, aY) => {
  let y = isNaN(aY) ? 0 : aY;
  let x = isNaN(aX) ? 0 : aX;

  return { x, y };
};

export const p2d = (point, screen, areaSize) => {
  var { x, y } = p2d_inner(point, screen, areaSize);
  const b_ref = p2d_inner(screen.bottomViewPos, screen, areaSize);
  const t_ref = p2d_inner(screen.topViewPos, screen, areaSize);

  return noNaN(x, b_ref.y - y + t_ref.y);
};

export const d2p = (point, screen, areaSize) => {
  var { x, y } = d2p_inner(point, screen, areaSize);
  const b_ref = d2p_inner(
    p2d_inner(screen.bottomViewPos, screen, areaSize),
    screen,
    areaSize
  );
  const t_ref = d2p_inner(
    p2d_inner(screen.topViewPos, screen, areaSize),
    screen,
    areaSize
  );

  return noNaN(Math.round(x), Math.round(b_ref.y - y + t_ref.y));
};

export const d2pd = (distance, screen, areaSize) => {
  const screenDistance = Math.min(screen.width, screen.height);
  return (distance * screenDistance) / (areaSize * extraWidth);
};
