import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div>
        <section className="pt-8 pt-md-11">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                {/*<!-- Heading -->*/}
                <h1 className="display-4 text-center">
                  TRAGSYS helps you design and optimise your grounding system
                </h1>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>

        {/*<!-- SECTION -->*/}
        <section className="pt-6 pt-md-8">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                {/*<!-- Caption -->*/}
                <h2 className="fw-bold">Basic Assumptions</h2>

                {/*<!-- Text -->*/}
                <p>
                  Conductors are solid and straight, with horizontal or vertical
                  orientation with circular cross section. They are bare and
                  completely buried in soil, and are electrically characterized
                  by conductivity. Conductor configuration may consist of
                  arbitrary connected (and/or separated) networks of conductors.
                  Soil is homogeneous and characterized by conductivity and
                  permittivity. Conductor structure is energized by current
                  injection in arbitrary number of points. The current is
                  harmonic in frequency domain and is typical lightning (or user
                  defined) impulse in time domain.
                </p>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>

        {/*<!-- SECTION -->*/}
        <section className="pt-6 pt-md-8">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                {/*<!-- Fugure -->*/}
                <figure className="figure mb-0">
                  {/*<!-- Image -->*/}
                  <img
                    src="assets/logos/logo-edf.svg"
                    alt="..."
                    style={{ width: 120 }}
                  />

                  {/*<!-- Caption -->*/}
                  <figcaption className="figure-caption text-left mt-3">
                    Field Measurement and Validation
                    <p>
                      The results produced by the TRAGSYS calculation engine
                      were validated and compared for correctness with field
                      measurements by the Électricité de France.
                    </p>
                  </figcaption>
                </figure>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>

        {/*<!-- SECTION -->*/}
        <section className="pt-6 pt-md-8">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                {/*<!-- Heading -->*/}
                <h2 className="fw-bold">Calculation Engine</h2>

                {/*<!-- Text -->*/}
                <p>
                  Computations on the following quantities are computed in
                  frequency domain and in time domain:
                </p>
                <ul className="mb-4">
                  <li>Longitudinal current distribution in conductors;</li>
                  <li className="mt-4">
                    Conductor and soil potentials along profiles and/or grid of
                    points;
                  </li>
                  <li className="mt-4">
                    Voltages between points in soil or at the surface along
                    arbitrary paths;
                  </li>
                  <li className="mt-4">
                    Impedance to ground related to current injection point at
                    the conductor network.
                  </li>
                </ul>
                <p>
                  In frequency domain phasors are given by modulus, phase, real
                  and imaginary values.
                </p>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>
      </div>
    );
  }
}

export default About;
