import React, { Component } from "react";
import { Link } from "react-router-dom";

class Landing extends Component {
  render() {
    return (
      <div>
        {/*<!-- WELCOME -->*/}
        <section className="mt-n11 pt-12 pb-8 pt-md-14 pb-md-11 bg-black">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10 text-center">
                {/*<!-- Heading -->*/}
                <h1
                  className="display-1 fw-bold text-white"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Grounding Systems Analysis
                </h1>

                {/*<!-- Text -->*/}
                <p
                  className="lead text-gray-500 mb-6 mx-auto"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  style={{ maxWidth: 500 }}
                >
                  TRAGSYS is a SaaS product designed for analysis of low and
                  high frequency, as well as the transient behavior of grounding
                  structures. It uses a rigorous mathematical formulations
                  derived from the complete set of Maxwell&rsquo;s equations,
                  numerically solved by the method of moments.
                </p>

                {/*<!-- Button -->*/}
                <p
                  className="mb-7 mb-md-9"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <Link
                    to={`/about`}
                    key="about"
                    className="btn btn-info shadow lift"
                  >
                    Read More <i className="fe fe-arrow-right ms-3"></i>
                  </Link>
                </p>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
            <div className="row">
              <div className="col-12" data-aos="fade-up" data-aos-delay="200">
                {/*<!-- Media -->*/}
                <div className="position-relative pt-7 pb-7 ps-7 mb-7 mb-md-9 rounded-4 shadow-multicolor overflow-hidden">
                  {/*<!-- Background -->*/}
                  <div className="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor"></div>

                  {/*<!-- Image -->*/}
                  <img
                    className="position-relative img-fluid rounded-start shadow-lg"
                    src="assets/img/screenshots/desktop/ui-alt.jpg"
                    alt="..."
                  />
                </div>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
            <div className="row align-items-center justify-content-center">
              <div className="col-12">
                {/*<!-- Heading -->*/}
                <h6 className="text-uppercase text-center text-info mb-6">
                  Trusted by
                </h6>
              </div>
              <div className="col-6 col-sm-3 col-md-2 mb-4 mb-md-0">
                {/*<!-- Brand -->*/}
                <div className="img-fluid text-white mb-2 mb-md-0">
                  <img
                    src="assets/logos/logo-edf.svg"
                    alt="..."
                    style={{ width: 120 }}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-2 mb-4 mb-md-0">
                {/*<!-- Brand -->*/}
                <div className="img-fluid text-white mb-2 mb-md-0">
                  <img
                    src="assets/logos/SE_Logo_White_RGB.svg"
                    alt="..."
                    style={{ width: 120 }}
                  />
                </div>
              </div>
              <div className="col-6 col-sm-3 col-md-2 mb-4 mb-md-0">
                {/*<!-- Brand -->*/}
                <div className="img-fluid text-white mb-2 mb-md-0">
                  <img
                    src="assets/logos/emerson-logo-data-1368354.png"
                    style={{ width: 120 }}
                    alt="..."
                  />
                </div>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>

        {/*<!-- FEATURES -->*/}
        <section className="bg-gradient-dark-black py-8 py-md-11">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7 text-center">
                {/*<!-- Heading -->*/}
                <h2 className="display-3 fw-bold text-white">
                  From Design to Precise Results
                </h2>

                {/*<!-- Text -->*/}
                <p className="lead text-white mb-9">
                  TRAGSYS features complete UI design environment which is
                  closely integrated with the calculation engine, providing the
                  full flexibility of graphical design, 2D and 3D results
                  display and various text output formats for maximal precision.
                </p>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
            <div className="row mt-n7">
              <div className="col-12 col-sm-6 col-lg-4 mt-7">
                {/*<!-- Card -->*/}
                <div
                  className="card h-100 rounded-3"
                  style={{ background: "#1b2a4e" }}
                >
                  <div className="card-body p-5 pb-0 my-auto">
                    <div className="icon d-inline-flex p-3 rounded-circle bg-info text-white">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path d="M0 0h24v24H0z" />
                          <path
                            d="M4.953 14.303l-1.906-.606C4.65 8.644 7.673 6 12 6c4.327 0 7.349 2.644 8.953 7.697l-1.906.606C17.688 10.023 15.377 8 12 8s-5.688 2.022-7.047 6.303zM12 8a1 1 0 100-2 1 1 0 000 2z"
                            fill="#335EEA"
                            opacity=".3"
                          />
                          <path
                            d="M5.732 6h3.439a3.001 3.001 0 015.658 0h3.439a2 2 0 110 2h-3.439a3.001 3.001 0 01-5.658 0H5.732a2 2 0 110-2zM12 8a1 1 0 100-2 1 1 0 000 2zM4 19a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2zm16 2a3 3 0 110-6 3 3 0 010 6zm0-2a1 1 0 100-2 1 1 0 000 2z"
                            fill="#335EEA"
                          />
                        </g>
                      </svg>{" "}
                    </div>
                  </div>
                  <div className="card-body p-5">
                    {/*<!-- Heading -->*/}
                    <h3 className="fw-bold text-white">Design Surface</h3>

                    {/*<!-- Text -->*/}
                    <p className="text-muted mb-0">
                      Beautiful and rich design environment built for grounding
                      systems.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 mt-7">
                {/*<!-- Card -->*/}
                <div
                  className="card h-100 rounded-3"
                  style={{ background: "#1b2a4e" }}
                >
                  <div className="card-body p-5 pb-0 my-auto">
                    {/*<!-- Letter -->*/}
                    <div
                      className="lh-1 fw-bolder text-warning"
                      style={{ fontSize: 100 }}
                    >
                      x=
                    </div>
                  </div>
                  <div className="card-body p-5">
                    {/*<!-- Heading -->*/}
                    <h3 className="fw-bold text-white">Calculation Engine</h3>

                    {/*<!-- Text -->*/}
                    <p className="text-muted mb-0">
                      Rigorous mathematical formulations derived from the
                      complete set of Maxwell&rsquo;s equations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-4 mt-7">
                {/*<!-- Card -->*/}
                <div
                  className="card h-100 rounded-3"
                  style={{ background: "#1b2a4e" }}
                >
                  <div className="card-body p-5 pb-0 my-auto">
                    {/*<!-- Palette -->*/}
                    <div className="d-inline-flex flex-row-reverse">
                      <div className="p-6 rounded-circle bg-primary ms-n3"></div>
                      <div className="p-6 rounded-circle bg-gray-800 ms-n3"></div>
                      <div className="p-6 rounded-circle bg-gradient-multicolor"></div>
                    </div>
                  </div>
                  <div className="card-body p-5">
                    {/*<!-- Heading -->*/}
                    <h3 className="fw-bold text-white">Rich Visualizations</h3>

                    {/*<!-- Text -->*/}
                    <p className="text-muted mb-0">
                      2D and 3D results display and various text output formats
                      for maximal precision.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- / .row -->*/}
          </div>
        </section>

        {/*<!-- ABOUT -->*/}
        <section className="pt-8 pt-md-11 bg-black">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-md-6 col-lg-5">
                {/*<!-- Heading -->*/}
                <h2 className="display-3 fw-bold text-white mb-6 mb-md-8">
                  Interact
                </h2>

                {/*<!-- Text -->*/}
                <p className="text-muted lead mb-6">
                  <span className="text-white fw-bold">Flexible.</span> TRAGSYS
                  alows full interaction with the grounding system, enabling the
                  user to use trial and measure techniques with ease and
                  flexibility.
                </p>

                {/*<!-- Text -->*/}
                <p className="text-muted lead mb-7 mb-md-0">
                  <span className="text-white fw-bold">Efficient.</span> Heavily
                  optimised and tuned calculation engine allows you to get
                  results very quickly, enabling easy experimentation.
                </p>
              </div>
              <div className="col-12 col-md-6">
                {/*<!-- Media -->*/}
                <div
                  className="position-relative vw-md-50 pt-7 ps-7 ps-md-9 overflow-hidden"
                  style={{ maxWidth: 800 }}
                >
                  {/*<!-- Background -->*/}
                  <div className="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor rounded-4"></div>

                  {/*<!-- Image -->*/}
                  <img
                    className="position-relative w-100 w-md-150"
                    src="assets/img/screenshots/desktop/ui-alt-mixed-cropped.png"
                    alt="..."
                  />
                </div>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>
        </section>

        {/*<!-- ABOUT -->*/}
        <section className="pt-8 pt-md-11 bg-black">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-md-6 col-lg-5 order-md-1">
                {/*<!-- Heading -->*/}
                <h2 className="display-4 fw-bold text-white mb-6 mb-md-8">
                  Output Results
                </h2>

                {/*<!-- Text -->*/}
                <p className="text-muted lead mb-6">
                  <span className="text-white fw-bold">
                    Plots and Perspectives.
                  </span>{" "}
                  Output results are given as 2D plots and/or 3D perspectives.
                  These results can be exported in EPS (Encapsulated PostScript)
                  file format, as an ASCII text data or downloaded as an image.
                </p>

                {/*<!-- Text -->*/}
                <p className="text-muted lead mb-7 mb-md-0">
                  <span className="text-white fw-bold">
                    Interactivity and Animation.
                  </span>{" "}
                  Animation is enabled for both the 2D plots and the 3D
                  perspectives, on results in frequency and time domains.
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-6 order-md-0">
                {/*<!-- Positioner -->*/}
                <div className="position-relative">
                  {/*<!-- Media -->*/}
                  <div
                    className="position-relative vw-md-50 p-7 p-md-9 ps-0 overflow-hidden float-end"
                    style={{ maxWidth: 800 }}
                  >
                    {/*<!-- Background -->*/}
                    <div className="position-absolute top-0 end-0 bottom-0 start-0 bg-gradient-multicolor rounded-4"></div>

                    {/*<!-- Image -->*/}
                    <img
                      className="position-relative w-100 w-md-120 float-end"
                      src="assets/img/screenshots/ui/output.png"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>
        </section>

        {/*<!-- IMAGE -->*/}
        <section
          className="py-14 py-lg-16 jarallax"
          data-jarallax
          data-speed=".8"
          style={{ backgroundImage: "url(assets/img/covers/cover-19.jpg)" }}
        >
          {/*<!-- Shape -->*/}
          <div className="shape shape-top shape-fluid-x text-black">
            <svg
              viewBox="0 0 2880 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor" />
            </svg>{" "}
          </div>

          {/*<!-- Shape -->*/}
          <div className="shape shape-bottom shape-fluid-x text-black">
            <svg
              viewBox="0 0 2880 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M720 125L2160 0h720v250H0V125h720z"
                fill="currentColor"
              />
            </svg>{" "}
          </div>
        </section>

        {/*<!-- CTA -->*/}
        <section className="py-10 py-md-14 bg-black">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">
                {/*<!-- Pretitle -->*/}
                <h6 className="text-uppercase text-info mb-6">
                  Electrical safety for the future
                </h6>

                {/*<!-- Heading -->*/}
                <h1 className="display-1 fw-bold text-white">
                  Build your new grounding system the easy way.
                </h1>

                {/*<!-- Text -->*/}
                <p className="fs-lg text-gray-500 mb-6 mb-md-8">
                  Optimize the material and build time of your grounding system
                  without breaking project deadlines.
                </p>

                {/*<!-- Button -->*/}
                <a href="#!" className="btn btn-info lift">
                  Request a demo <i className="fe fe-arrow-right ms-2"></i>
                </a>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>

        {/*<!-- BORDER -->*/}
        <div className="bg-black">
          <div className="container border-top border-gray-900-50"></div>
        </div>
      </div>
    );
  }
}

export default Landing;
