import axios from "axios";

export const SELECT_DRAW_TOOL = "select_draw_tool";
export const PUT_ALL_NODES = "put_all_nodes";
export const PUT_NODE = "put_node";
export const SELECT_SHAPE = "select_shape";
export const WIRE_START = "wire_start";
export const WIRE_END = "wire_end";
export const PUT_WIRE = "put_wire";
export const PUT_WIRES = "put_wires";
export const MESH_START = "mesh_start";
export const MESH_END = "mesh_end";
export const REMOVE_NODE = "remove_node";
export const REMOVE_WIRE = "remove_wire";
export const SHOW_SETTINGS = "show_settings";
export const UNDO_ACTION = "undo";
export const REDO_ACTION = "redo";
export const SAVE_SETTINGS = "save_settings";
export const PUT_ALL_NODES_AND_WIRES = "put_all_nodes_and_wires";
export const POS = "pos";
export const SCALED_POS = "scaled_pos";
export const REFRESH = "refresh";
export const SPLIT_WIRES = "split_wires";
export const VIEW_SIZE = "view_size";
export const SAVE_STATE = "save_state";

const API_ROOT = `${process.env.REACT_APP_API_BASE}`;

export const selectDrawTool = (tool) => async (dispatch) => {
  dispatch({
    type: SELECT_DRAW_TOOL,
    payload: tool,
  });
};

export const putAllNodes = (nodes) => async (dispatch) => {
  dispatch({
    type: PUT_ALL_NODES,
    payload: nodes,
  });
};

export const putNode = (node) => async (dispatch) => {
  dispatch({
    type: PUT_NODE,
    payload: node,
  });
};

export const selectShape = (shapeId) => async (dispatch) => {
  dispatch({
    type: SELECT_SHAPE,
    payload: shapeId,
  });
};

export const wireStart = (node) => async (dispatch) => {
  dispatch({
    type: WIRE_START,
    payload: node,
  });
};

export const wireEnd = (point) => async (dispatch) => {
  dispatch({
    type: WIRE_END,
    payload: point,
  });
};

export const putWire = (wire) => async (dispatch) => {
  dispatch({
    type: PUT_WIRE,
    payload: wire,
  });
};

export const putWires = (wires) => async (dispatch) => {
  dispatch({
    type: PUT_WIRES,
    payload: wires,
  });
};

export const meshStart = (point) => async (dispatch) => {
  dispatch({
    type: MESH_START,
    payload: point,
  });
};

export const meshEnd = (point) => async (dispatch) => {
  dispatch({
    type: MESH_END,
    payload: point,
  });
};

export const removeNode = (node) => async (dispatch) => {
  dispatch({
    type: REMOVE_NODE,
    payload: node,
  });
};

export const removeWire = (wire) => async (dispatch) => {
  dispatch({
    type: REMOVE_WIRE,
    payload: wire,
  });
};

export const showSettings = (show) => async (dispatch) => {
  dispatch({
    type: SHOW_SETTINGS,
    payload: show,
  });
};

export const saveSettings = (settings) => async (dispatch) => {
  dispatch({
    type: SAVE_SETTINGS,
    payload: settings,
  });
};

export const undo = () => async (dispatch) => {
  dispatch({
    type: UNDO_ACTION,
    payload: true,
  });
};

export const redo = () => async (dispatch) => {
  dispatch({
    type: REDO_ACTION,
    payload: true,
  });
};

export const putAllNodesAndWires = (nodes, wires) => async (dispatch) => {
  dispatch({
    type: PUT_ALL_NODES_AND_WIRES,
    payload: { nodes, wires },
  });
};

export const setPointerPos = (pos) => async (dispatch) => {
  dispatch({
    type: POS,
    payload: pos,
  });
};

export const setScaleAndOffset = (scale, offset) => async (dispatch) => {
  dispatch({
    type: SCALED_POS,
    payload: { scale, offset },
  });
};

export const setViewSize = (width, height) => async (dispatch) => {
  dispatch({
    type: VIEW_SIZE,
    payload: { width, height },
  });
};

export const refresh = () => async (dispatch) => {
  dispatch({
    type: REFRESH,
  });
};

export const splitWires = (dict) => async (dispatch) => {
  const nodes = [];
  Object.keys(dict).forEach(function (key, index) {
    nodes.push(dict[key]);
  });

  dispatch({
    type: SPLIT_WIRES,
    payload: nodes,
  });
};

export const saveState = (id, nodes, wires, settings) => async (dispatch) => {
  const flatWires = wires.map((w) => {
    return { start: w.start.id, end: w.end.id };
  });
  const response = await axios.post(
    `${API_ROOT}/1/1/geometry`,
    {
      id,
      nodes,
      wires: flatWires,
      settings,
    },
    { withCredentials: true }
  );

  dispatch({ type: SAVE_STATE, payload: response });
};
