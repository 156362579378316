import React, { Component } from "react";
import { Link } from "react-router-dom";

class NavBar extends Component {
  render() {
    return (
      <div>
        {/*<!-- NAVBAR */}
        <nav className={`navbar navbar-expand-lg ${this.props.theme}`}>
          <div className="container-fluid">
            {/*<!-- Brand */}
            <Link
              to={`/`}
              key="logo"
              style={{ textDecoration: "none" }}
              className="navbar-brand"
            >
              TRAGSYS
            </Link>

            {/*<!-- Collapse */}
            <div className="collapse navbar-collapse" id="navbarCollapse">
              {/*<!-- Navigation */}
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown">
                  <Link
                    to={`/`}
                    key="home"
                    style={{ textDecoration: "none" }}
                    className="nav-link dropdown-toggle"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to={`/about`}
                    key="about"
                    style={{ textDecoration: "none" }}
                    className="nav-link dropdown-toggle"
                  >
                    About
                  </Link>
                  {/*<!-- / .row */}
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to={`/references`}
                    key="references"
                    style={{ textDecoration: "none" }}
                    className="nav-link dropdown-toggle"
                  >
                    References
                  </Link>
                </li>
              </ul>

              {/*<!-- Button */}
              <a
                className={`navbar-btn btn btn-sm ${this.props.btnTheme} lift ms-auto`}
                href="#"
              >
                Login
              </a>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavBar;
