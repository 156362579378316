import { combineReducers } from "redux";
import Data from "./reducer_data";
import Toolbar from "./reducer_toolbar";
import Draw from "./reducer_draw";
import Settings from "./reducer_settings";

const rootReducer = combineReducers({
  toolbar: Toolbar,
  data: Data,
  draw: Draw,
  settings: Settings,
});

export default rootReducer;
