import { Route, Routes } from "react-router-dom";

import NavBar from "./public/NavBar";
import Footer from "./public/Footer";
import FooterWhite from "./public/FooterWhite";

export default function App({ children }) {
  return (
    <div>
      <Routes>
        <Route
          path="/about"
          element={
            <NavBar theme="navbar-light bg-white" btnTheme="btn-primary" />
          }
        />
        <Route
          path="/references"
          element={
            <NavBar theme="navbar-light bg-white" btnTheme="btn-primary" />
          }
        />
        <Route
          path="/"
          element={<NavBar theme="navbar-dark" btnTheme="btn-info" />}
        />
      </Routes>
      {children}
      <Routes>
        <Route path="/about" exact element={<FooterWhite />} />
        <Route path="/references" exact element={<FooterWhite />} />
        <Route path="/" exact element={<Footer />} />
      </Routes>
    </div>
  );
}
