import React, { Component } from "react";

class References extends Component {
  render() {
    return (
      <div>
        <section className="pt-8 pt-md-11">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                {/*<!-- Heading -->*/}
                <h1 className="display-4 text-center">
                  Research paper references
                </h1>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>

        {/*<!-- SECTION -->*/}
        <section className="pt-6 pt-md-8">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                {/*<!-- Text -->*/}
                <ol className="mb-4">
                  <li>
                    L. Grcev, J. B. M. van Waes, A. P. J. van Deursen, M. J. M.
                    van Riet and F. Provoost,{" "}
                    <a href="docs/Grcev_Cired03.pdf" class="contentmediumblue">
                      &ldquo;High Frequency Current Distribution at HV Tower
                      With GSM System Between Earth Wires, Tower Grounding and
                      LV/MV Cable Network&rdquo;
                    </a>
                    , 17th International Conference on Electricity Distribution
                    (CIRED), Barcelona, Spain, 12-15 May 2003, Session 2, Paper
                    No. 20, pp. 1-5.
                  </li>
                  <li className="mt-4">
                    L. Grcev,{" "}
                    <a href="docs/Grcev_EMCZurich03.pdf">
                      &ldquo;Dynamic Behavior of Grounding Systems&rdquo;
                    </a>
                    , International Zurich Symposium on Electromagnetic
                    Compatibility (EMC Zurich 2003), Zurich, Switzerland,
                    February 18-20, 2003, Paper 106P6, pp. 569-574.
                  </li>
                  <li className="mt-4">
                    L. Grcev,{" "}
                    <a href="docs/Grcev01_Intelec.pdf">
                      &ldquo;Modeling of Grounding Systems for Better Protection
                      of Communication Installations against Effects from
                      Electric Power System and Lightning,&rdquo;
                    </a>{" "}
                    Proc. of the International Telecommunications Energy
                    Conference (INTELEC&rsquo;2001), Birmingham, UK, October
                    2001 Proc. of the International Telecommunications Energy
                    Conference (INTELEC&rsquo;2001), Birmingham, UK, October
                    2001, IEE Conference Publication No. 484, pp. 461-468.
                  </li>
                  <li className="mt-4">
                    L. Grcev,{" "}
                    <a href="docs/GROUND2000_1.pdf">
                      &ldquo;High-Frequency Performance of Ground Rods in Highly
                      Resistive Soil,&rsquo;&rsquo;
                    </a>{" "}
                    Proc. of the International Conference on Grounding and
                    Earthing (GROUND'2000), Belo Horizonte, Brazil, June 2000,
                    pp. 85-89.
                  </li>
                  <li className="mt-4">
                    L. Grcev,{" "}
                    <a href="docs/EMCRoma98_1.pdf">
                      &ldquo;Improved Design of Transmission Line Grounding
                      Arrangements for Better Protection against Effects of
                      Lightning,&rsquo;&rsquo;
                    </a>{" "}
                    Proceedings of the International Symposium on
                    Electromagnetic Compatibility (EMC&rsquo;98 ROMA), Roma,
                    Italy, September 1998, Paper C1-7, pp. 100-103.
                  </li>
                  <li className="mt-4">
                    L. Grcev, N. Grcevski,{" "}
                    <a href="docs/GROUND98_2.pdf">
                      &lsquo;&lsquo;Software Methods for Interactive
                      Optimization of High Frequency and Transient Performance
                      of Complex Grounding Systems,&rsquo;&rsquo;
                    </a>{" "}
                    Proc. of the International Conference on Grounding and
                    Earthing (GROUND'98), Belo Horizonte, Brazil, 1998, pp.
                    68-73.
                  </li>
                  <li className="mt-4">
                    L. Grcev, N. Grcevski,
                    <a href="docs/ICLP98_2.pdf">
                      {" "}
                      &ldquo;Software Techniques for Interactive Optimization of
                      Complex Grounding Arrangements for Protection against
                      Effects of Lightning,&rsquo;&rsquo;
                    </a>{" "}
                    in Proceedings of the International Conference on Lightning
                    Protection (ICLP'98), Birmingham, U.K., 1998, Vol. 1, pp.
                    518-523.
                  </li>
                  <li className="mt-4">
                    L. Grcev, V. Arnautovski,
                    <a href="docs/ICLP98_1.pdf">
                      {" "}
                      &ldquo;Comparison between simulation and measurement of
                      frequency dependent and transient characteristics of power
                      transmission line grounding,&rsquo;&rsquo;
                    </a>{" "}
                    in Proc. of 24-th International Conference on Lightning
                    protection (ICLP'98), Birmingham, U.K., 14-18 Sept. 1998,
                    Vol. 1, pp. 524-529.
                  </li>
                  <li className="mt-4">
                    L. Grcev,{" "}
                    <a href="docs/Grcev_Cigre98.PDF">
                      &ldquo;Improved Earthing System Design Practices for
                      Reduction of Transient Voltages,&rdquo;{" "}
                    </a>
                    Proceedings of 1998 CIGR&Eacute; Session &ndash;
                    International Conference on Large High Voltage Electric
                    Systems, (Paper 36-302), Paris, France, August 1998.
                  </li>
                  <li className="mt-4">
                    L. Grcev, V. Arnautovski, N. Grcevski,
                    <a href="docs/Lm97_2.pdf">
                      {" "}
                      &ldquo;Software Methods for Analysis of the Efficiency of
                      Different Grounding Arrangements for Lightning
                      Protection,&rsquo;&rsquo;
                    </a>{" "}
                    Proceedings of the Second International Symposium: Lightning
                    and Mountains, Chamonix-Mont-Blanc, Paper A6.2, pp. 235-238,
                    France, June 1997.
                  </li>
                  <li className="mt-4">
                    L. Grcev, V. Arnautovski,
                    <a href="docs/Lm97_1.pdf">
                      {" "}
                      &ldquo;Frequency Dependent and Transient Impedance of
                      Grounding Systems: Comparison Between Simulation and
                      Measurement,&rsquo;&rsquo;
                    </a>{" "}
                    Proceedings of the Second International Symposium: Lightning
                    and Mountains, Paper A6.3, pp. 239-244, Chamonix-Mont-Blanc,
                    France, June 1997.
                  </li>
                  <li className="mt-4">
                    L. Grcev, M. Heimbach,
                    <a href="docs/ICLP_GH.pdf">
                      {" "}
                      &ldquo;Computer Simulation of Transient Ground Potential
                      Rise in Large Earthing Systems,&rsquo;&rsquo;
                    </a>{" "}
                    Proceedings of the 23rd International Conference on
                    Lightning Protection (ICLP&rsquo;96), Paper 5.15, pp.
                    585-590, Firenze, Italy, 23-27 September 1996.
                  </li>
                  <li className="mt-4">
                    L. Grcev,{" "}
                    <a href="docs/Grcev92_IEEE-EMC.pdf">
                      &ldquo;Computation of Transient Voltages Near Complex
                      Grounding Systems Caused by Lightning
                      Currents,&rsquo;&rsquo;
                    </a>{" "}
                    Proceedings of the IEEE International Symposium on
                    Electromag&shy;netic Compatibility, pp. 393-399, Anaheim,
                    California, U.S.A., 1992
                  </li>
                  <li className="mt-4">
                    M. Heimbach, L. Grcev, &ldquo;Grounding System Analysis in
                    Transients Programs Applying Electromagnetic Field
                    Approach,&rdquo; IEEE Transaction on Power Delivery, Vol.
                    12, pp. 186-193, No. 1, January 1997 (also presented at
                    IEEE/PES 1996 Summer Meeting, Denver, USA, July 1996).
                  </li>
                  <li className="mt-4">
                    L. Grcev, M. Heimbach, &ldquo;Frequency Dependent and
                    Transient Characteristics of Substation Grounding
                    Systems,&rdquo; IEEE Transaction on Power Delivery, Vol. 12,
                    pp. 172-178, No. 1, January 1997 (also presented at IEEE/PES
                    1996 Summer Meeting, Denver, USA, July 1996).
                  </li>
                  <li className="mt-4">
                    L. Grcev, &ldquo;Computer Analysis of Transient Voltages in
                    Large Grounding Systems,&rsquo;&rsquo; IEEE Transaction on
                    Power Delivery, Vol. 11, pp. 815-823, No. 2, April 1996
                    (also presented at IEEE/PES 1995 Summer Meeting, Portland,
                    USA, July 1995).
                  </li>
                  <li className="mt-4">
                    F. Menter, L. Grcev, &ldquo;EMTP-Based Model for Grounding
                    Systems Analysis,&rsquo;&rsquo; IEEE Transactions on Power
                    Delivery, Vol. 9, pp. 1838-1849, No. 4, October 1994 (also
                    presented at IEEE/PES 1994 Winter Meeting, New York, USA,
                    January 1994).{" "}
                  </li>
                  <li className="mt-4">
                    L. Grcev, F. Dawalibi, &ldquo;An Electromagnetic Model for
                    Transients in Grounding Systems,&rsquo;&rsquo; IEEE
                    Transactions on Power Delivery, Vol. 5, pp. 1773-1781, No.
                    4, November 1990 (also presented at IEEE/PES 1990 Winter
                    Meeting, Atlanta, USA, January 1990)
                  </li>
                </ol>
              </div>
            </div>{" "}
            {/*<!-- / .row -->*/}
          </div>{" "}
          {/*<!-- / .container -->*/}
        </section>
      </div>
    );
  }
}

export default References;
