import { SELECT_DRAW_TOOL } from "../actions";
import { POINTER } from "../components/ui/ToolBtn";

export default function Toolbar(state = { selectedTool: POINTER }, action) {
  switch (action.type) {
    case SELECT_DRAW_TOOL:
      return { ...state, selectedTool: action.payload };
    default:
      return state;
  }
}
