import {
  WIRE_START,
  WIRE_END,
  MESH_END,
  MESH_START,
  POS,
  SCALED_POS,
  VIEW_SIZE,
} from "../actions";

export default function Draw(
  state = {
    wireStartNode: null,
    wireEndPoint: null,
    meshStartPoint: null,
    meshEndPoint: null,
    pointerPos: { x: 0, y: 0 },
    scale: 1,
    scaledOffset: { x: 0, y: 0 },
    viewHeight: 0,
    viewWidth: 0,
  },
  action
) {
  switch (action.type) {
    case WIRE_START:
      const node = action.payload;
      if (!node) {
        return { ...state, wireStartNode: null, wireEndPoint: null };
      }
      const point = { x: node.x, y: node.y };
      return { ...state, wireStartNode: node, wireEndPoint: point };
    case WIRE_END:
      return { ...state, wireEndPoint: action.payload };
    case MESH_START:
      return {
        ...state,
        meshStartPoint: action.payload,
        meshEndPoint: action.payload,
      };
    case MESH_END:
      return { ...state, meshEndPoint: action.payload };
    case POS: {
      let pos = action.payload;
      pos.x = Math.max(0, pos.x);
      pos.y = Math.max(0, pos.y);
      return { ...state, pointerPos: pos };
    }
    case SCALED_POS: {
      const { scale, offset } = action.payload;
      return { ...state, scale, scaledOffset: { x: offset.x, y: offset.y } };
    }
    case VIEW_SIZE: {
      const { width, height } = action.payload;
      return { ...state, viewWidth: width, viewHeight: height };
    }
    default:
      return state;
  }
}
