import { ulid } from "ulid";

import {
  PUT_ALL_NODES,
  PUT_ALL_NODES_AND_WIRES,
  PUT_NODE,
  PUT_WIRE,
  PUT_WIRES,
  REMOVE_NODE,
  REMOVE_WIRE,
  SELECT_SHAPE,
  SAVE_STATE,
} from "../actions";
import { UNDO_ACTION, REDO_ACTION, REFRESH, SPLIT_WIRES } from "../actions";

import { intersection } from "../components/util/geometry";
import { nodeId, wireId } from "../components/util/id";

const UNDO_STACK_SIZE = 10;

const pushToUndo = (nodes, wires, undoStack, undoStackPtr) => {
  if (undoStackPtr < undoStack.length) {
    undoStack = undoStack.slice(0, undoStackPtr);
  }
  undoStack.push(JSON.stringify({ nodes, wires }));
  if (undoStack.length >= UNDO_STACK_SIZE) {
    undoStack = undoStack.slice(1, undoStack.length);
  }

  return { undoStack, undoStackPtr: undoStack.length, saveId: ulid() };
};

const patchWires = (nodes, wires) => {
  var nodesLookup = {};
  for (var i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    nodesLookup[node.id] = node;
  }

  for (var j = 0; j < wires.length; j++) {
    const wire = wires[j];
    const startNode = nodesLookup[wire.start.id];
    const endNode = nodesLookup[wire.end.id];

    wire.start = startNode;
    wire.end = endNode;
  }
};

const undo = (undoStack, undoStackPtr) => {
  if (undoStackPtr < 1) {
    return { undoStack, undoStackPtr };
  }

  undoStackPtr--;

  if (undoStackPtr === 0) {
    return { undoStack, undoStackPtr, nodes: [], wires: [] };
  }

  var { nodes, wires } = JSON.parse(undoStack[undoStackPtr - 1]);

  patchWires(nodes, wires);

  return { undoStack, undoStackPtr, nodes, wires, saveId: ulid() };
};

const redo = (undoStack, undoStackPtr) => {
  if (undoStackPtr === undoStack.length) {
    return { undoStack, undoStackPtr };
  }

  var { nodes, wires } = JSON.parse(undoStack[undoStackPtr++]);

  patchWires(nodes, wires);

  return { undoStack, undoStackPtr, nodes, wires, saveId: ulid() };
};

export default function Data(
  state = {
    nodes: [],
    selectedShape: null,
    wires: [],
    undoStack: [],
    undoStackPtr: 0,
    saveId: "-",
  },
  action
) {
  switch (action.type) {
    case PUT_ALL_NODES_AND_WIRES:
      var { nodes, wires } = action.payload;
      return {
        ...state,
        nodes,
        wires,
        ...pushToUndo(nodes, wires, state.undoStack, state.undoStackPtr),
      };
    case PUT_ALL_NODES:
      return {
        ...state,
        nodes: action.payload,
        ...pushToUndo(
          action.payload,
          state.wires,
          state.undoStack,
          state.undoStackPtr
        ),
      };
    case PUT_NODE: {
      let nodes = state.nodes.slice();
      nodes.push(action.payload);

      return {
        ...state,
        nodes,
        ...pushToUndo(nodes, state.wires, state.undoStack, state.undoStackPtr),
      };
    }
    case REMOVE_NODE: {
      const node = action.payload;
      const nodeIdx = state.nodes.indexOf(node);

      if (nodeIdx >= 0) {
        let nodes = state.nodes.slice();
        nodes.splice(nodeIdx, 1);
        let wires = state.wires.filter(
          (w) => w.start.id !== node.id && w.end.id !== node.id
        );

        return {
          ...state,
          nodes,
          wires,
          selectedShape: null,
          ...pushToUndo(nodes, wires, state.undoStack, state.undoStackPtr),
        };
      } else {
        return state;
      }
    }
    case PUT_WIRE: {
      const wire = action.payload;
      var filtered = state.wires.filter(
        (w) => w.start.id === wire.start.id && w.end.id === wire.end.id
      );

      if (filtered.length > 0) {
        return state;
      }

      let wires = state.wires.slice();
      let nodes = state.nodes.slice();

      var splits = [];

      if (true) {
        filtered = state.wires.filter(
          (w) =>
            w.start.id !== wire.start.id &&
            w.end.id !== wire.end.id &&
            w.end.id !== wire.start.id &&
            w.start.id !== wire.end.id
        );

        for (var i = 0; i < filtered.length; i++) {
          const w = filtered[i];
          if (
            w.start === wire.start ||
            w.end === wire.start ||
            w.start === wire.end ||
            w.end === wire.end
          ) {
            continue;
          }
          const point = intersection(wire, w);
          if (point) {
            const node = {
              x: point.x,
              y: point.y,
              id: nodeId(),
            };
            splits.push({ node, w });
            nodes.push(node);
          }
        }

        splits.sort(
          (a, b) =>
            Math.hypot(wire.start.x - a.node.x, wire.start.y - a.node.y) <
            Math.hypot(wire.start.x - b.node.x, wire.start.y - b.node.y)
        );

        for (var i = 0; i < splits.length; i++) {
          const { node, w } = splits[i];
          wires.push({
            id: wireId(),
            start: wire.start,
            end: node,
          });
          const wireEndNode = w.end;
          w.end = node;
          wires.push({
            id: wireId(),
            start: node,
            end: wireEndNode,
          });
          wire.start = node;
        }
      }

      //console.log({anyIntersections: splits.length > 0});

      wires.push(wire);

      return {
        ...state,
        wires,
        nodes,
        ...pushToUndo(nodes, wires, state.undoStack, state.undoStackPtr),
      };
    }
    case REMOVE_WIRE: {
      const wireIdx = state.wires.indexOf(action.payload);

      if (wireIdx >= 0) {
        let wires = state.wires.slice();
        wires.splice(wireIdx, 1);

        return {
          ...state,
          wires,
          ...pushToUndo(
            state.nodes,
            wires,
            state.undoStack,
            state.undoStackPtr
          ),
        };
      } else {
        return state;
      }
    }
    case PUT_WIRES: {
      let wires = state.wires.slice();
      wires = wires.concat(action.payload);

      return {
        ...state,
        wires,
        ...pushToUndo(state.nodes, wires, state.undoStack, state.undoStackPtr),
      };
    }
    case REFRESH: {
      return { ...state };
    }
    case SELECT_SHAPE:
      return { ...state, selectedShape: action.payload };
    case UNDO_ACTION:
      return { ...state, ...undo(state.undoStack, state.undoStackPtr) };
    case REDO_ACTION:
      return { ...state, ...redo(state.undoStack, state.undoStackPtr) };
    case SPLIT_WIRES: {
      const affectedNodes = action.payload;
      const map = {};
      for (var i = 0; i < affectedNodes.length; i++) {
        const node = affectedNodes[i];
        map[node.id] = node;
      }

      let wires = state.wires.slice();
      let nodes = state.nodes.slice();

      var attached = state.wires.filter(
        (w) => map[w.start.id] && map[w.end.id]
      );

      for (var i = 0; i < attached.length; i++) {
        var wire = attached[i];
        const midX = (wire.start.x + wire.end.x) / 2;
        const midY = (wire.start.y + wire.end.y) / 2;

        const node = {
          x: midX,
          y: midY,
          id: nodeId(),
        };
        nodes.push(node);
        const end = wire.end;
        wire.end = node;

        wires.push({
          id: wireId(),
          start: node,
          end: end,
        });
      }

      return {
        ...state,
        wires,
        nodes,
        ...pushToUndo(nodes, wires, state.undoStack, state.undoStackPtr),
      };
    }
    case SAVE_STATE: {
      console.log(action.payload);

      return state;
    }
    default:
      return state;
  }
}
