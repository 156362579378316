import { SAVE_SETTINGS, SHOW_SETTINGS } from "../actions";

export default function Data(
  state = {
    edit: false,
    installation_depth: 30,
    draw_area_size: 100,
  },
  action
) {
  switch (action.type) {
    case SAVE_SETTINGS:
      return { ...state, ...action.payload };
    case SHOW_SETTINGS:
      return { ...state, edit: action.payload };
    default:
      return state;
  }
}
